<template>
    <article id="wrap">
      <AppHeader theme="green">販売申請</AppHeader>

      <section class="contents">

        <div class="contentInner">

          <div class="ta_c txt_16 bold mb_10">商品情報入力</div>

          <div class="ta_c txt_9 mb_10">販売したい商品情報を入力してください。</div>

          <div class="mb_20">
            <label for="item_name">商品名<span class="must">[必須]※20文字</span></label>
            <div class="error" v-if="$v._name.$dirty && !$v._name.required">入力してください</div>
            <div class="error" v-if="$v._name.$dirty && !$v._name.maxLength">20文字以内で入力してください</div>
            <input type="text" id="item_name" placeholder="商品名"
                   :value="_name" @input="$emit('update:_name', $event.target.value)">
          </div>

          <div class="mb_20">
            <label>商品画像<span class="must">[必須]※4点まで</span></label>
            <div class="error" v-if="$v.photos.$dirty && !$v.photos.attachedPhoto">商品画像が１点以上必要です</div>
            <input ref="file" class="file-button" type="file" accept="image/*" @change="upload" />
            <ul class="file_upload">
              <template v-for="n in 4">
                <li :key="n">
                  <img src="@/assets/img/mypage/file_upload.png" alt="ファイルアップロード画像" @click="$refs.file.click()" v-if="!photos[n-1]">
                  <img :src="photos[n-1]" alt="ファイルアップロード画像" @click="$refs.file.click()" v-if="photos[n-1]">
                  <div class="trash"><a href="#" @click="removePhoto(n-1)"><img src="@/assets/img/talk/icon_trash.png" alt="ゴミ箱ボタン"></a></div>
                </li>
              </template>
            </ul>
          </div>

          <div class="mb_20">
            <label for="item_price">販売価格(税込)<span class="must">[必須]</span></label>
            <button type="button" class="p-menu-browse-detail-tooltip c-tooltip c-tooltip-button c-tooltip--nowrap">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
                <span class="c-tooltip__contents">
                    <span class="c-tooltip__text">
                        ステキナセカイでは決済手数料としまして1件につき¥300をいただいております。<br>販売価格は¥300〜設定可能です。
                    </span>
                </span>
            </button>
            <div class="error" v-if="$v.price.$dirty && !$v.price.required">入力してください</div>
            <div class="error" v-if="$v.price.$dirty && !$v.price.between">300〜9,999,999の範囲で入力してください</div>
            <input type="number" id="item_price" :value="price" @input="$emit('update:price', Number($event.target.value))">円
          </div>

          <div class="mb_20">
            <label for="item_volume">販売個数<span class="must">[必須]</span></label>
            <div class="error" v-if="$v.value.$dirty && !$v.value.required">入力してください</div>
            <input type="number" id="item_volume" :value="value" @input="$emit('update:value', Number($event.target.value))">
          </div>

          <div class="mb_20">
            <label for="item_content">商品説明<span class="must">[必須]</span></label>
            <div class="error" v-if="$v.detail.$dirty && !$v.detail.required">入力してください</div>
            <textarea id="item_content" rows="5" cols=""
                      :value="detail" @input="$emit('update:detail', $event.target.value)"></textarea>
          </div>

        </div><!--.contentInner-->

      </section>

      <hr class="item_hr">

      <section class="contents">
        <div class="contentInner">
          <div id="buy_rule">
            <div class="formCheckbox">
              <div class="error" v-if="$v.$dirty && $v.accept.$error">チェックを入れてください</div>
              <input type="checkbox" id="checkbox01" :checked="accept"
                     @input="$emit('update:accept', !!$event.target.value)">
              <label for="checkbox01" class="checkbox"><strong>※ステキナセカイでは決済手数料としまして1件につき¥300をいただいております。</strong><br>
                禁止されている行為および出品物を必ずご確認ください。<br>
                偽ブランド品や盗品物などの販売は犯罪であり、<br>
                法律により処罰される可能性があります。<br>
                出品申請をもちまして規約に同意したことになります。</label>
            </div>
<!--            <div class="error" v-if="$v.accept.$dirty && !$v.accept.required">入力してください</div>-->
          </div>
        </div><!--.contentInner-->
      </section>

      <hr class="item_hr">

      <section class="contents pb_150">
        <div class="contentInner">
          <div class="bt_form mb_30 responsive">
            <button type="button" value="send" @click="next">入力内容を確認する</button>
          </div>
        </div><!--.contentInner-->
      </section>

      <AppFooter fixed></AppFooter>
    </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import {validationMixin} from 'vuelidate';
import {required, sameAs, between, maxLength} from 'vuelidate/lib/validators';
const attachedPhoto = (photos) => photos.length > 0;
export default {
  components: { AppHeader, AppFooter },
  props: {
    _name: String,
    price: Number,
    value: Number,
    detail: String,
    accept: Boolean,
    photos: Array
  },
  data () {
    return {
      maxItemLength: 4,
    }
  },
  computed: {
    isArtist () {
      return !!this.$store.state.loginUser.artist_id;
    },
    name () {
      return this._name;
    }
  },
  mixins: [validationMixin],
  validations: {
    _name: {
      required,
      maxLength: maxLength(20),
    },
    price: {
      required,
      between: between(300, 9999999)
    },
    value: {
      required,
    },
    detail: {
      required,
    },
    photos: {
      attachedPhoto
    },
    accept: {
      sameAs: sameAs( () => true )
    },
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      // const response = await this.$http.get(`artists/${this.$route.params.artist}`);
      // this.artist = response.data;
    },
    next () {
      if (!this.invalid()) {
        this.$router.push({name: 'item-create-confirm'});
      } else {
        this.scrollTop();
      }
    },
    invalid () {
      this.$v.$touch();
      return this.$v.$invalid;
    },
    scrollTop() {
      const top = 0;
      window.scroll({top, behavior: 'smooth'});
    },
    clickButton () {
      this.$refs.file.click();
    },
    async upload (event) {
      const files = event.target.files || event.dataTransfer.files;
      if (files.length === 0) return false;
      if (this.photos.length >= this.maxItemLength) {
        this.$swal('', `商品画像は${this.maxItemLength}点までです`, 'error');
        return false;
      }
      const file = files[0];
      this.$store.commit('setLoading');

      if (this.checkFile(file)) {
        const picture = await this.getBase64(file);
        this.photos.push(picture);
        this.$emit('upload:photos', this.photos);
      } else {
        this.$toasted.error('画像をアップロードできませんでした。（対応：JPG / PNG 最大5MB）', {
          position: 'top-center',
          duration: 2000,
        });
      }
      this.$store.commit('resetLoading');
      return false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    checkFile(file) {
      let result = true
      const SIZE_LIMIT = 5000000 // 5MB
      // キャンセルしたら処理中断
      if (!file) {
        result = false
      }
      // jpeg か png 関連ファイル以外は受付けない
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        result = false
      }
      // 上限サイズより大きければ受付けない
      if (file.size > SIZE_LIMIT) {
        result = false
      }
      return result
    },
    removePhoto(index) {
      this.$emit('upload:photos', this.photos.splice(index, 1));
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .bt_form.mb_30.responsive {
    margin-bottom: 120px;
  }
}
.file-button {
  display: none;
}
ul.file_upload li img {
  cursor: pointer;
}
.error {
  color: red;
  padding: 5px 10px;
}

.contentInner, .tab_list {
    padding: 10px 20px;
}

/* ツールチップの基本スタイル */
.c-tooltip {
    position: relative;
    display: inline-block;
}

.c-tooltip .c-tooltip__contents {
    visibility: hidden;
    width: 200px;
    background-color: #0c0c0c;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 9px;
    position: absolute;
    z-index: 999;
    bottom: 125%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
}

.c-tooltip .c-tooltip__contents::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: #0c0c0c transparent transparent transparent;
}

/* ホバー時のスタイル */
.c-tooltip:hover .c-tooltip__contents {
    visibility: visible;
    opacity: 1;
}

/* ボタンおよびアイコンの枠線を消す */
.p-menu-browse-detail-tooltip, .fa-question-circle {
    border: none;
}

/* もしボタンにpaddingやbackgroundなどのスタイルがあれば、それも必要に応じて調整する */
.p-menu-browse-detail-tooltip {
    background: transparent;
    padding-left: 5px;
}

</style>